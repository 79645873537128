var rex_php_tags = new RegExp( "<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim" );
var rex_script_tags = new RegExp( "<script(?:[\\n\\r]|.)+?\\/script>", "gim" );
var rex_comments_tags = new RegExp( "<!--(?:[\\n\\r]|.)+?-->", "gim" );

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents( str ) {

    return str.replace( rex_php_tags, '' );
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents( str ) {

    return str.replace( rex_script_tags, '' );
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments( str ) {

    return str.replace( rex_comments_tags, '' );
}

function stripCombo( str ) {

    var output = stripHTMLComments( str );

    output = stripJSContents( output );
    output = stripPhpContents( output );

    return output;
}

// Shows button's underlying loader and triggers defined action
function waitingProcess( loader, action, id_form ) {

    var $this = $( this ),
        $loader = $( loader );

    this.style.display = 'none';

    if ( !$loader.is( ':visible' ) ) {

        $loader.css( 'display', 'block' );

        switch ( action ) {

            case 0:
                window.setTimeout( ajax_checkAllForm, 1000, tabAuthTop, 1, path_relative_root );
                break;

            case 1:
                window.setTimeout( ajax_checkAllForm, 1000, tabLostPwdTop, 1, path_relative_root );
                break;

            case 2:
                window.setTimeout( ajaxDeconnect, 1000, path_relative_root );
                break;

            case 3:
                window.setTimeout( checkAllForm, 1000, tabError, 0, path_relative_root, id_form );
                break;

            case 4:
                window.setTimeout( ajax_checkAllForm, 1000, tabAuth, 1, path_relative_root );
                break;

            case 5:
                window.setTimeout( ajax_checkAllForm, 1000, tabLostPwd, 1, path_relative_root );
                break;

            case 6:
                window.setTimeout( checkFormParrainage, 1000 );
                break;

            case 7:
                if ( checkAllForm( tabErrorGrattez, 0, path_relative_root, id_form ) ) {

                    window.setTimeout( processGrattezGagnez(), 1000 );
                }
                break;
        }
    }

    setTimeout( function () {
        $this.css( 'display', 'block' );
        $loader.css( 'display', 'none' );
    }, 2500 );
}

// Checks any form
function ajax_checkAllForm( tabError, blockMenuTopConnexionInfobulle, path_relative_root ) {

    if ( checkAllForm( tabError, blockMenuTopConnexionInfobulle ) ) {

        if ( tabError[ 0 ][ 1 ] && tabError[ 0 ][ 1 ] == 'clientMailAuthTop' ) {

            $.ajax( {
                url: path_relative_root + 'ajax_login_allowed.php',
                type: 'get',
                data: {
                    mail: document.getElementById( tabError[ 0 ][ 1 ] ).value,
                    mdp: document.getElementById( tabError[ 1 ][ 1 ] ).value,
                    is_top: true
                },
                success: function ( res ) {

                    var url, index_deb, index_fin, provenance;

                    if ( res[ 0 ] !== '0' ) {

                        url = window.location.pathname;
                        index_deb = url.lastIndexOf( '/' );
                        index_fin = url.lastIndexOf( '.' );
                        provenance = url.substring( index_deb + 1, index_fin );

                        if ( provenance === 'connexion' ) {

                            window.location.replace( path_relative_root + create_link( 'client_index' ) );
                        } else if ( provenance === 'identification' ) {

                            window.location.replace( path_relative_root + 'livraison-kiala.php' );
                        } else {

                            window.location.reload();
                        }
                    } else {

                        document.getElementById( 'AuthTopErr' ).style.display = 'block';

                        $( '.loader' ).css( 'display', 'none' );
                    }
                }
            } );
        } else if ( tabError[ 0 ][ 1 ] && tabError[ 0 ][ 1 ] == 'mailLostTop' ) {

            var mail = document.getElementById( tabError[ 0 ][ 1 ] ).value;

            $.ajax( {
                url: path_relative_root + 'ajax_email_exist.php',
                type: 'get',
                data: { mail: mail },
                success: function ( res ) {

                    if ( res == 1 ) {

                        $.ajax( {
                            url: path_relative_root + 'ajax_send_new_password_link.php',
                            type: 'get',
                            data: { mail: mail },
                            succes: function () {

                                show_lightbox( 'popup_confirm_reinitialisation_mdp' );

                                document.getElementById( 'lostpwdTopErr' ).style.display = 'none';
                                document.getElementById( 'lostpwdTopSuccesTitle' ).style.visibility = 'hidden';

                                hideLoader();
                            }
                        } );
                    } else {

                        document.getElementById( 'lostpwdTopErr' ).style.display = 'block';

                        hideLoader();
                    }
                }
            } );
        } else if ( tabError[ 0 ][ 1 ] && tabError[ 0 ][ 1 ] == 'clientMailAuth' ) {

            $.ajax( {
                url: path_relative_root + 'ajax_login_allowed.php',
                type: 'get',
                data: {
                    mail: document.getElementById( tabError[ 0 ][ 1 ] ).value,
                    mdp: document.getElementById( tabError[ 1 ][ 1 ] ).value,
                    is_top: false
                },
                success: function ( res ) {

                    hideLoader();

                    if ( res == 1 ) {

                        $( '#auth' ).submit();

                        return true;
                    } else {

                        document.getElementById( 'AuthErrErr' ).style.display = 'block';

                        return false;
                    }
                }
            } );
        } else if ( tabError[ 0 ][ 1 ] && tabError[ 0 ][ 1 ] == 'mailLost' ) {

            var mail = document.getElementById( tabError[ 0 ][ 1 ] ).value;

            $.ajax( {
                url: path_relative_root + 'ajax_email_exist.php',
                type: 'get',
                data: { mail: mail },
                success: function ( res ) {

                    if ( res == 1 ) {

                        $.ajax( {
                            url: path_relative_root + 'ajax_send_new_password_link.php',
                            type: 'get',
                            data: { mail: mail },
                            success: function ( res ) {

                                show_lightbox( 'popup_confirm_reinitialisation_mdp' );

                                document.getElementById( 'lostpwdErr' ).style.display = 'none';
                                document.getElementById( 'lostpwdSuccesTitle' ).style.visibility = 'hidden';

                                hideLoader();

                                return false;
                            }
                        } );
                    } else {

                        document.getElementById( 'lostpwdErr' ).style.display = 'block';

                        hideLoader();

                        return false;
                    }
                }
            } );
        }
    } else {

        $( '.loader' ).css( 'display', 'none' );
    }
}

// Ensures this.value is non-empty
// Binds to HTMLInputElement
// Returns true if value is non-empy, false otherwise
function nonEmpty() {

    return !!this.value.trim();
}

// Adds "display" rule to bond element's style with a value of "block"
function show() {

    this.style.display = "block";
}

// Adds "display" rule to bond element's style with a value of "none"
function hide() {

    this.style.display = "none";
}

var tooltip_counter = null;

function showTooltip( elem, msg ) {

    var $this = $( elem ),
        e_pos = {

            'top': $this.offset().top - ( $( '#bloc_erreur' ) ).height(),
            'left': $this.offset().left - 16
        },
        message = ajax_file( path_relative_root + 'ajax_sprintf.php?arg1=' + msg ),
        clone = $( '#bloc_erreur' ).clone();

    if ( tooltip_counter !== null ) {

        clearTimeout( tooltip_counter );
    }

    $( '#bloc_erreur' ).remove();
    $( 'body' ).prepend( clone );

    $( '#erreur_msg' ).html( message );

    $( '#bloc_erreur' ).stop( true, false ).hide().css( {

        'opacity': 1,
        'top': e_pos.top,
        'left': e_pos.left,
        'z-index': 1000
    } ).fadeIn( 400 );
}

function hideTooltip() {

    tooltip_counter = setTimeout( function () {

        $( '#bloc_erreur' ).fadeOut( 400 );
    }, 600 );
}

// Adds leading zeroes to a given value
function zeroPad( nbr, base ) {

    var zpad;

    base = ( parseInt( base ) > 1 ) ? base : 10;
    zpad = ( nbr < base ) ? '0' : '';

    return zpad + nbr.toString( base ).toUpperCase();
}

function parseArgs( args ) {
    var obj = {},
        inc = 0,
        len = args.length;
    for ( inc; inc < len; inc += 1 ) {
        obj[ 'arg' + inc ] = args[ inc ];
    }
    return obj;
}

function translate() {

    var o_data = parseArgs( arguments ),
        s_resp = arguments[ 0 ];
    var path_relative_root = document.getElementById( 'path' ).value;
    $.ajax( {
        url: path_relative_root + 'ajax_sprintf.php',
        type: 'get',
        data: o_data,
        async: false,
        success: function ( res ) { s_resp = res }
    } );

    return s_resp;
}

// Alters item quantity in cart
function changeQte( type ) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if ( types.includes( type ) ) {

        item = this.getValue( 'item_id', parseFloat );
        loader = document.getElementById( 'qte_loader_' + item );
        qtty = this.getValue( 'selected_qte', parseFloat );

        //this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';
        $( '.selected_qte' ).addClass( 'hidden' );

        switch ( type ) {

            case 'plus':
                qtty += 1;
                break;

            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax( {
            url: create_link( 'order_basket' ),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function ( res ) {

                try {
                    res = JSON.parse( res );
                } catch ( e ) {
                    console.error( 'JSON ERROR: could not parse data' );

                    return false;
                }

                res = $( '<div />' ).html( res.datas );

                document.getElementById( 'main_cart_wrapper' ).innerHTML = $( '#main_cart_wrapper', res ).html();

                /* RELOAD SLIDER GOODIES CART */
                var goodiesSlider = new Swiper( '.goodiesSlider', {

                    spaceBetween: 0,
                    slidesPerView: 3,
                } );

                if ( type == "plus" ) {
                    showLightBoxSelectionOfferte();
                }
            },
        } );
    }
}

function openMultiShad( id, fixed ) {

    var $lbId = $( '#' + id );

    if ( $lbId.length > 0 ) {
        if ( $( 'body' ).children( '#' + id ).length === 0 ) {

            $lbId.add( $( '#shade' ) ).appendTo( 'body' );
        }

        $lbId.css( { opacity: 0, display: 'block' } )
            .animate( { opacity: 1 }, 350, function () {

                $lbId.css( { opacity: '' } );
            } );


        if ( !fixed ) {

            $( 'html, body' ).animate( { scrollTop: 0 }, 300 );
        }

        $( '#shade' ).unbind( 'click' )
            .on( 'click', function () {

                closeMultiShad( event );
            } ).css( { opacity: 0, display: 'block', cursor: 'pointer' } )
            .animate( { opacity: 0.6 }, 350 );

        if ( $( '#lightbox_achat_express' ).length > 0 && id != 'lightbox_achat_express' ) {

            $( '#lightbox_achat_express' ).fadeOut();
            $( '#shade' ).data( 'opened_lb', $( '#shade' ).data( 'opened_lb' ) - 1 );
        }

        if ( $( '#shade' ).data( 'opened_lb' ) ) {
            $( '#shade' ).data( 'opened_lb', $( '#shade' ).data( 'opened_lb' ) + 1 );
        } else {
            $( '#shade' ).data( 'opened_lb', 1 );
        }

        if ( navigator.userAgent.indexOf( 'MSIE' ) != -1 && navigator.userAgent.indexOf( '7.' ) != -1 ) {
            $( '#topMenuConnexionButtonInside' ).css( { zIndex: 1 } );
        }

        if ( $( '#galleyLightbox' ).length > 0 ) {

            var swiperLightbox = new Swiper( '.sliderLightbox', {

                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev',
                preloadImages: false,
                lazyLoading: true,
                autoHeight: true
            } );

            var sliderThumbs = new Swiper( '.sliderThumbs', {

                spaceBetween: 15,
                centeredSlides: true,
                slidesPerView: 'auto',
                slideToClickedSlide: true
            } );

            swiperLightbox.params.control = sliderThumbs;
            sliderThumbs.params.control = swiperLightbox;
        }
    }




}

function openGallery( id, plane, fixed ) {

    var $lbId = $( '#' + id );

    if ( $lbId.length > 0 ) {

        if ( $( 'body' ).children( '#' + id ).length === 0 ) {

            $lbId.add( $( '#shade' ) ).appendTo( 'body' );
        }

        $lbId.css( { opacity: 0, display: 'block' } ).animate( { opacity: 1 }, 350, function () {

            $lbId.css( { opacity: '' } );
        } );

        if ( !fixed ) {

            $( 'html, body' ).animate( { scrollTop: 0 }, 300 );
        }

        $( '#shade' ).unbind( 'click' ).on( 'click', function () {

            closeMultiShad( event );
        } ).css( { opacity: 0, display: 'block', cursor: 'pointer' } ).animate( { opacity: 0.6 }, 350 );

        if ( plane == '1' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "travel.html" );

        } else if ( plane == '2' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "stearman.html" );

        } else if ( plane == '3' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "t6.html" );

        } else if ( plane == '13' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "b18.html" );

        } else if ( plane == '14' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "t28.html" );

        } else if ( plane == '85' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "s-2b.html" );

        } else if ( plane == '110' ) {
            $( "#galleryLightboxWrapper" ).load( path_relative_root + "tb-30.html" );
        }

        if ( $( '#galleyLightbox' ).length > 0 ) {

            setTimeout( function () {
                var swiper = new Swiper( ".sliderThumbs", {
                    loop: true,
                    spaceBetween: 15,
                    slidesPerView: 4,
                    watchSlidesProgress: true,
                    slideToClickedSlide: true,
                } );

                var swiper2 = new Swiper( ".sliderLightbox", {
                    loop: true,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    thumbs: {
                        swiper: swiper,
                    },
                } );
            }, 1000 );
        }
    }
}

function closeMultiShad( evt, id ) {

    var array_id_popup = new Array( 'mention_legales_pop_up', 'lightbox_achat_express', 'modbox', 'modbox_parrainage', 'modbox_error', 'popup_savoir_plus', 'alertbox', 'alertbox1', 'alertboxlogin', 'sendfriendbox', 'popup_inscription_home', 'errbox', 'popup_prix_reference', 'modboxpromo', 'selectionOffertePopup', 'sendwishlistbox', 'galleyLightbox', 'article_fourth_box_cgv', 'reservationConfirm' ),
        cnt_id_popup = array_id_popup.length;

        //Problème avec la lightbox newsletter, ajout d'une vérif
        if (evt != 'exit_overlay_OVI') {
            evt.preventDefault();
        } else {
            $('#exit_overlay_OVI').css('display', 'none');
        }

    if ( $.inArray( id, array_id_popup ) == -1 && id != '' && id != undefined ) {

        var $lbId = $( '#' + id );

        var startIdOpacity = Number( $lbId.css( 'opacity' ) );

        $lbId.css( { opacity: startIdOpacity } ).animate( { opacity: 0 }, 350, function () {

            $lbId.css( { display: 'none', opacity: '' } ).stop().clearQueue();
        } );
    } else {

        for ( var i = 0; i < cnt_id_popup; i++ ) {

            if ( $( '#' + array_id_popup[ i ] ).length && $( '#' + array_id_popup[ i ] ).is( ':visible' ) ) {

                var $lbId = $( '#' + array_id_popup[ i ] ),
                    startIdOpacity = Number( $lbId.css( 'opacity' ) );

                $lbId.css( { opacity: startIdOpacity } ).animate( { opacity: 0 }, 350, function () {

                    $lbId.css( { display: 'none', opacity: '' } ).stop().clearQueue();
                } );
            }
        }
    }

    var startShadOpacity = Number( $( '#shade' ).css( 'opacity' ) );

    $( '#shade' ).css( { opacity: startShadOpacity } ).animate( { opacity: 0 }, 350, function () {

        $( '#shade' ).css( { display: 'none', opacity: '' } );

        if ( $( 'html' ).hasClass( 'ie7' ) ) {

            $( '#shade' )[ 0 ].style.removeAttribute( 'filter' );
            $( 'html, body' ).css( { overflow: '' } );
        }
    } );
}

// Reload product from ref
function loadProduct( new_id, type, page ) {

    var old_id = this.getValue( 'produit_id' );
    var col_id = this.getValue( this.getValue( 'idCouleur' ) );
    var sze_id = this.getValue( this.getValue( 'idTaille' ) );
    var act = this.getValue( 'act' );
    var qte_itm = this.elements.namedItem( 'qteProd' ).value;
    var is_model = this.getValue( 'hdn_is_model' );
    var data = {
        produit_id: old_id,
        produit_principal: old_id,
        couleurProd: col_id,
        tailleProd: sze_id,
        act: act,
        qteProd: qte_itm,
        hdn_product_id: old_id,
        hdn_is_model: is_model,
        produit_id_new: new_id,
        type: type,
        type_page: ( page !== undefined ) ? page : 'product'
    };

    $.ajax( {
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function () {

        },
        success: function ( res ) {

            try {

                res = $.parseJSON( res );
            } catch ( e ) {

                console.error( 'parseJSON' );
                return;
            }

            if ( res.success == 'ok' ) {

                var datas = res.datas;

                if ( data.type_page == 'product' ) {

                    var $html_main = $( stripCombo( datas.html_main ) );
                    var $html_middle = $( stripCombo( datas.html_middle ) );
                    var color = $( "#ligne_couleur" ).html();

                    var main_wrapper = $( '.product_main_wrapper', $html_main );
                    var info_wrapper = $( '.product_info_wrapper', $html_main );

                    $( '.item_price_wrapper', $html_main ).next( 'div' ).after( $html_middle ).remove();

                    var contents = $( '<div />' ).html( $html_main );

                    var video = $( '.wrapper_video', contents );

                    $( '.product_main_wrapper', '#site_global_wrap' ).after( main_wrapper ).remove();
                    $( '.product_info_wrapper', '#site_global_wrap' ).after( info_wrapper ).remove();
                    $( "#ligne_couleur" ).html( color );
                    $( 'input.color' ).prop( 'checked', false );
                    $( 'input#color_' + data.couleurProd ).prop( 'checked', true );
                    $( '#videoBox', '#site_global_wrap' ).remove();
                    $( '#shade' ).after( video );
                } else if ( type_page == 'look' ) {

                    var $html_main = $( datas.main_content );

                    $( '#ref_look_' + produit_principal ).html( $( '#ref_look_' + produit_principal, $html_main ).html() );
                    $( '#look_add_lightbox_' + produit_principal ).html( $( '#look_add_lightbox_' + produit_principal, $html_main ).html() );

                    calculTotal( 'slide_look_detail' );
                    $( '#itm_thumb_list' ).empty();
                    var thumbnails = loadPrevisu();
                    parseThumbList( thumbnails );

                    var $id_current = id_prod.substring( id_prod.lastIndexOf( "_" ) + 1 );
                    var i = array_look.indexOf( parseInt( $id_current ) );

                    if ( i != -1 ) {

                        array_look.splice( i, 1 );
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme( 'liste_qte_' + datas.product.id, datas.product.id, path_relative_root );

                }
            } else {
                $( '#btn_error' ).fadeIn();
            }
        },
        complete: function () {

            var heads = $( "[data-tab-head]" );
            var containers = $( "[data-tab-container]" );

            if ( window.YT_Player !== undefined ) {

                window.YT_Player.destroy();

                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function ( event ) {
                event.preventDefault();

                var element = $( this );
                var tab_number = element.attr( "data-tab" );
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass( "current" ).siblings().removeClass( "current" );
                container_name = head_container.parent().attr( "data-tab-head" );
                container = containers.filter( "[data-tab-container='" + container_name + "']" );
                scrollpane = container.find( "[data-tab]" ).hide().filter( "[data-tab='" + tab_number + "']" ).show().find( '.scroll-pane' );

                if ( scrollpane.length > 0 ) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout( function () {

                var more_vis = $( '.more_vis' );
                var grabber;

                more_vis.each( function () {

                    var jqzoom = $( this ).next( '#jqzoom' );

                    if ( jqzoom.length > 0 ) {

                        $( this ).height( jqzoom.height() );

                        if ( document.getElementById( 'visual_scroller' ) ) {

                            setTimeout( function () {

                                grabber = new Grabber( document.getElementById( 'visual_scroller' ), true, false );
                            }, 250 );
                        }
                    }
                } );

                $( window ).on( 'resize', function () {

                    more_vis.each( function () {

                        var jqzoom = $( this ).next( '#jqzoom' );

                        if ( jqzoom.length > 0 ) {

                            $( this ).height( jqzoom.height() );
                        }
                    } );
                } );
            }, 250 );

            heads.each( function () {
                var head = $( this );
                var children = head.children();
                children.removeClass( "current" ).filter( ":first" ).addClass( "current" );
                children.find( '[data-tab]' ).click( activateTab );
            } );

            $( "[data-tab-container]" ).children( 'div' ).eq( Math.abs( 1 - $( '.current', "[data-tab-head]" ).index() ) ).css( 'display', 'none' );

            var assocs_elm = $( '#assocs_slider', '#site_global_wrap' ).get( 0 );
            var ranges_elm = $( '#ranges_slider', '#site_global_wrap' ).get( 0 );

            var slider_cfg = {
                slideMargin: 10,
                slideSelector: '.item',
                infiniteLoop: false,
                hideControlOnEnd: true,
                easing: 'ease-in-out',
                pager: false,
                minSlides: 4,
                maxSlides: 4,
                moveSlides: 1,
                slideWidth: 280
            };

            if ( assocs_elm && $( '.item', assocs_elm ).length > 0 ) {

                $( assocs_elm ).bxSlider( slider_cfg );
            }

            if ( ranges_elm && $( '.item', ranges_elm ).length > 0 ) {

                $( ranges_elm ).bxSlider( slider_cfg );
            }
            getAvailableQuantity.call( $( 'form.product_info', '#site_global_wrap' ).get( 0 ), data.produit_id_new, data.qteProd, '', data.couleurProd );

            var sticky_elm = $( 'form.product_info', '#site_global_wrap' ).get( 0 );

            if ( sticky_elm ) {

                sticky_elm = new ScrollLock( sticky_elm.id );
            }

            $( '#addToWishlistButton', '#site_global_wrap' ).on( 'click', addToWishlist );

            checkProductInWishlist();
        }
    } );
}

// Called upon color change in product page
function onItemColorChange( form, is_regroup ) {

    var control, prod_id;

    if ( this.checked ) {

        control = form.elements.namedItem( form.getValue( 'idCouleur' ) );
        control.value = this.value.trim();

        prod_id = form.getValue( 'itm_id_' + control.value ).trim();

        if ( is_regroup ) {

            loadProduct.call( form, prod_id, 'couleur' );
        } else {

            var img = $( this ).data( 'pic' );

            changeVisu( 'img_large', img );
            showQteListe( 'liste_qte', prod_id, path_relative_root );
        }
    }
}

// Refreshes available quantity
function getAvailableQuantity( id, qty, from, idCouleur ) {


    var form = ( this instanceof HTMLFormElement ) ? this : document.getElementById( 'prod_info_' + id );
    var list = $( '.qte_select_wrapper', form ).get( 0 );
    var pcol = form.getValue( 'idCouleur' );
    var psze = form.getValue( 'idTaille' );
    var plnb = form.getValue( 'produit_lot_nb' );

    if ( idCouleur !== undefined ) {
        $( '#' + pcol ).val( idCouleur );
        $( "#color_" + idCouleur ).attr( "checked", "checked" );
    }


    var data = {
        idprod: form.getValue( 'produit_id' ),
        idcouleur: form.getValue( pcol ),
        idtaille: form.getValue( psze )
    };

    if ( from == 'basket' ) {
        data.suff = form.getValue( 'produit_id' );
    }

    if ( plnb ) {
        data.produit_lot_nb = plnb;
    }

    $.ajax( {
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        data: data,
        success: function ( res ) {

            var new_select;

            if ( list )
                list.innerHTML = res.trim();

            if ( qty ) {

                new_select = form.elements.namedItem( 'qteProd' );


                if ( $( '[value="' + qty + '"]', new_select ).length > 0 ) {

                    new_select.value = qty;
                } else {

                    new_select.value = $( 'option:last', new_select ).val();
                }
            }
        }
    } );
}

// YOUTUBE LOGIC
var YT_Player, YT_vid_id;

function onYouTubeIframeAPIReady() {

    YT_Player = new YT.Player( 'popup_video', {
        videoId: YT_vid_id,
        playerVars: {
            rel: 0
        }
    } );

    if ( $( '#videoBox' ).is( ':visible' ) ) {

        YT_Player.playVideo();
    }
}

function initYTPlayer() {

    var tag = document.createElement( 'script' );

    tag.id = 'YT_player_script';
    tag.src = "https://www.youtube.com/iframe_api";

    document.body.appendChild( tag );
}

function stop_video() {

    var video = $( '#videoBox' );

    video.css( { display: 'block', opacity: 1 } ).animate( { opacity: 0 }, function () {

        $( '#shade' ).stop( true, true ).fadeOut( 'slow', function () {

            if ( YT_Player !== undefined ) {

                YT_Player.seekTo( 0 ).stopVideo();
            }

            video.css( { display: 'none' } );
        } );
    } );
}

function show_video( vid ) {

    var video = $( '#videoBox' );

    $( window ).scrollTop( 0 );

    if ( !YT_Player ) {

        YT_vid_id = vid;

        if ( !document.getElementById( 'YT_player_script' ) ) {

            initYTPlayer();
        } else {

            onYouTubeIframeAPIReady();
        }
    }

    $( '#shade' ).off( 'click', stop_video ).after( video );
    $( '#shade' ).stop( true, true ).fadeIn( 'slow', function () {

        video.css( { display: 'block', opacity: 0 } ).animate( { opacity: 1 }, function () {

            /*if (YT_Player !== undefined) {

                    YT_Player.playVideo();
            }*/
        } );

        $( '#shade' ).one( 'click', stop_video );
    } );
}

function waitingAddToBasket_legacy( produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, is_lightbox_specifique ) {

    var id = '';
    if ( is_not_produit == true )
        id += '_' + produit_id;

    if ( is_lightbox_specifique )
        add_produit_specifique( produit_id, idCouleur, idTaille );
    else
        window.setTimeout( 'addToBasket_legacy("' + produit_id + '", "' + suff + '", "' + panier_id + '", "' + titreObjet + '", "' + idTaille + '", "' + lastTaille + '", "' + idCouleur + '", "' + lastCouleur + '","' + toEval + '", "' + page_name + '", "' + path_web + '", ' + enable_omniture + ', ' + is_not_produit + ');', 1000 );

    $( "#bloc_btn_active" + id ).attr( 'class', 'cache' );
    $( "#bloc_btn_loader" + id ).attr( 'class', 'f_right' );

}

function addToBasket_legacy( produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit ) {

    var id = '';

    if ( is_not_produit == true )
        id += '_' + produit_id;

    prix_id = 'prixU';
    if ( is_not_produit == true ) {
        var code_color = $( '#couleurUnique_' + produit_id ).text();
        var nom_color = $( '#couleurUnique_' + produit_id ).text();
        var prix_id = 'prixU_' + produit_id;
    }

    var array_panier = newUpdatePanier_legacy( produit_id, suff, panier_id, document.getElementById( prix_id ).value, path_web, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur );

    if ( array_panier != false ) {
        var type = '';

        if ( array_panier[ 1 ] == 'false' || array_panier.length == 0 ) {
            type = 'err';
        }

        omnitureEvent = '';
        var response = ajax_file( path_web + 'ajax_show_basket.php' );
        $( "#cart_top" ).html( response );
        $( ".cart_product_listing" ).lionbars();
        CartShow();

        if ( array_panier[ 1 ] === 0 ) {
            $( '#nb_article' ).html( array_panier[ 1 ] );
            omnitureEvent = '';
        }
        else if ( array_panier[ 1 ] == 1 ) {
            $( '#nb_article' ).html( array_panier[ 1 ] );
            $( '#panier' ).attr( 'href', path_web + create_link( 'order_basket' ) ).attr( 'style', 'cursor:pointer;' );

            omnitureEvent = 'scOpen,scAdd'; // le premier produit ajout� au panier
        }
        else {
            $( '#nb_article' ).html( array_panier[ 1 ] );
            $( '#panier' ).attr( 'href', path_web + create_link( 'order_basket' ) ).attr( 'style', 'cursor:pointer;' );

            omnitureEvent = 'scAdd'; // il y a d�j� un produit au panier
        }

        newshowmodal( array_panier[ 1 ], type, path_web, is_not_produit, produit_id );

        $( "#bloc_btn_active" + id ).attr( 'class', '' );
        $( "#bloc_btn_loader" + id ).attr( 'class', 'f_right cache' );

        if ( array_panier[ 1 ] > 0 && enable_omniture == true ) {
            void ( s.t() );
            s.pageName = "'" + page_name + "'";
            s.products = ";" + produit_id;
            s.events = omnitureEvent;
            void ( s.t() );
        }
    }
    else {

        $( "#bloc_btn_active" + id ).attr( 'class', '' );
        $( "#bloc_btn_loader" + id ).attr( 'class', 'f_right cache' );

    }

    eval( toEval );

    if ( array_panier != false ) {
        if ( navigator.userAgent.indexOf( 'MSIE' ) != -1 && navigator.userAgent.indexOf( '6.' ) != -1 ) {
            var svn = document.getElementsByTagName( "SELECT" );
            for ( a = 0; a < svn.length; a++ ) {
                svn[ a ].style.visibility = "hidden";
            }
        }
    }

    return false;
}

function newUpdatePanier_legacy( idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur ) {
    if ( i != '' ) {
        i = '_' + i;

        prixU = $( '#prixU' + i ).val();
    }

    var couleurs = document.getElementById( 'couleurProd' + i );
    var tailles = document.getElementById( 'tailleProd' + i );
    var $alertbox = $( '#alertbox1' );
    var res = '';
    var html_err = '';
    var flagError = false;
    if ( $( '#lightbox_achat_express' ).length > 0 ) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';
    if ( $alertbox.length == 0 ) {
        $alertbox = $( '<div id="alertbox1">' + html_err + '</div>' );
        $( 'body' ).append( $alertbox.hide() );
    }

    if ( tailles && tailles.value == '' ) {
        res = ajax_file( path_web + 'ajax_sprintf.php?arg1=js_error_size' );
        flagError = true;

    } else if ( couleurs && couleurs.value == '' ) {
        res = ajax_file( path_web + 'ajax_sprintf.php?arg1=js_error_couleur' );
        flagError = true;

    } else if ( document.getElementById( 'qteProd' + i ) && document.getElementById( 'qteProd' + i ).value == '' ) {
        res = ajax_file( path_web + 'ajax_sprintf.php?arg1=js_error_quantity' );
        flagError = true;
    }

    if ( flagError ) {
        $alertbox.find( '.txt_alert' ).html( res );
        openMultiShad( 'alertbox1', 'fixed' );
        return false;
    }
    else {

        var qte = 0;

        if ( $( '#qteProd' + i ) ) {
            qte = $( '#qteProd' + i ).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if ( couleurs && couleurs.value != 'none' ) idCouleur = couleurs.value;
        if ( tailles && tailles.value != 'none' ) idTaille = tailles.value;

        var array_response = new Array();
        var response = ajax_file( path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId );

        if ( response ) {

            array_response = response.split( "//" );

            var designation = array_response[ 0 ];

            if ( titreObjet.match( '^[0-9]' ) ) {

                if ( designation == 1 ) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if ( document.getElementById( idElTaille ) ) {

                if ( document.getElementById( idElTaille ).selectedIndex ) {

                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation += ' - Taille ' + document.getElementById( idElTaille ).options[ document.getElementById( idElTaille ).selectedIndex ].text;


                } else if ( lastTaille != '' ) {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ( document.getElementById( idElCouleur ) ) {

                if ( document.getElementById( idElCouleur ).selectedIndex ) {

                    //designation += ' - ' + document.getElementById(idElCouleur).text; // ne fonctionne pas
                    designation += ' - ' + document.getElementById( idElCouleur ).options[ document.getElementById( idElCouleur ).selectedIndex ].text;

                } else if ( lastCouleur != '' ) {

                    designation += ' - ' + lastCouleur;

                }

            }

            array_response[ 0 ] = designation;

        }

        return array_response;
    }
}

// Pretty straightforward: this formats a price
var formatPrice = ( function () {

    var THREEDIGITS = new RegExp( "\\d{3}", "g" );
    var FORMAT = window.price_format_plain;

    // Splits a number into chunks of 3 digits
    function splitThousands( nmbr ) {

        var f_chunk = '', chunk_length;

        nmbr = String( nmbr );

        chunk_length = nmbr.length % 3;

        if ( chunk_length ) {

            f_chunk = nmbr.slice( 0, chunk_length );
            nmbr = nmbr.substr( chunk_length );
        }

        return f_chunk + nmbr.replace( THREEDIGITS, function ( match ) {

            return ' ' + match;
        } );
    }

    return function ( price ) {
        var CURRENCY = window.siteCurrency;

        var output;

        if ( price.constructor !== String ) {

            price = price.toString( 10 );
        }

        price = price.replace( '.', ',' );
        price = price.split( ',' );

        output = FORMAT.replace( '[<unit>]', splitThousands( price[ 0 ] ) );
        output = output.replace( '[<symbol_right>]', CURRENCY.symbol_right );
        output = output.replace( '[<symbol_left>]', CURRENCY.symbol_left );

        if ( price[ 1 ] === undefined ) {

            price[ 1 ] = '00';
        } else if ( parseFloat( price[ 1 ] ) < 10 ) {

            price[ 1 ] = '0' + price[ 1 ];
        }

        output = output.replace( '[<decimal>]', price[ 1 ] );
        output = output.replace( '[<code>]', CURRENCY.code );

        return output;
    };
}() );

// Limits the length of an input
// oninput="limitInputLength.call(this, 100)"
// input.addEventListener('input', limitInputLength.bind(input, 100))
// $(input).on('input', limitInputLength.bind(input, 100))
function limitInputLength( max ) {

    this.value = this.value.slice( 0, max );

    return this;
}

// Called upon size change in product page
function onItemSizeChange( form ) {
    var control;

    if ( this.checked ) {
        control = form.elements.namedItem( 'tailleProd' );
        control.value = this.value.trim();

        // En fonction de la couleur on regarde si le produit est disponible
        getAvailableQuantity( $( '#produit_principal' ).val(), $( '#qteProd' ).val(), 'product' );
    }
}

// Refreshes available quantity
function getAvailableQuantity( id, qty, from ) {
    var form = ( this instanceof HTMLFormElement ) ? this : document.getElementById( 'prod_info_' + id );
    var color_id = $( '#couleurProd' ).val();
    var size_id = $( '#tailleProd' ).val();
    var plnb = form.getValue( 'produit_lot_nb' );

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if ( color_id == '' || size_id == '' ) {
        return;
    }

    var data = {
        idprod: form.getValue( 'produit_id' ),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if ( from == 'basket' ) {
        data.suff = form.getValue( 'produit_id' );
    }

    if ( plnb ) {
        data.produit_lot_nb = plnb;
    }

    $.ajax( {
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function ( res ) {
            if ( res.stock_tampon == 0 ) {
                $( "#bloc_add_basket" ).slideUp( "fast", function () {
                    // Set select value to no stock
                    $('#qteProd').html('<option class="no-stock">' + Translator.translate('product_no_stock_2') + '</option>');
                    $('.formItem.select').removeClass('has_stock').addClass('no_stock');
                } );
                $( '#ligne_qte' ).append( $( '.restock_date' ) );
            } else {
                $( "#bloc_add_basket" ).slideDown( "slow" );
                $('.formItem.select').addClass('has_stock').removeClass('no_stock');
                $('#qteProd').html($(res.content).find("option"));
                $('#qteProd').prop('selectedIndex', 0);
            }
        }
    } );
}

$( ".js-closeLightboxNewsletter" ).click( function () {
    $( "#shad" ).hide();
} );